.intro-slide3:after
{
  content: attr(data-content);
  color:#fff;
  position:absolute;
  width:100%; height:100%;
  top:0; left:0;
  background:rgba(0,0,0,0.6);
  opacity:0.6;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.category-banner:after
{
  content: attr(data-content);
  color:#fff;
  position:absolute;
  width:100%; height:100%;
  top:0; left:0;
  background:rgba(0,0,0,0.6);
  opacity:0.6;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.banner:after
{
  content: attr(data-content);
  color:#fff;
  position:absolute;
  width:100%; height:100%;
  top:0; left:0;
  background:rgba(0,0,0,0.6);
  opacity:0.6;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}